/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Box, Header as GHeader } from 'grommet';
import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { CompanyInfoProps } from '../context';
import LogoIcon from '../images/logo-white.svg';
import AnimatedNavbar from './animatedNavbar';
import HeaderCta from './headerCta';
import { HeaderFrom } from './HeaderFrom';
import { MaxWidth } from './maxWidth';
import MobileNavbar from './mobileNavbar';

interface HeaderProps {
    siteTitle?: string;
    companyInfo: CompanyInfoProps;
}

const StyledLogo = styled(Box)`
    /* ${props => console.log(props.theme)} */
    width: 180px;
    flex-shrink: 0;
    @media (min-width: 1024px) {
        width:200px;
    }
    @media (min-width: 1300px) {
        width: 220px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
`;
const StyledLarge = styled.div`
    @media (max-width: 991px) {
        display: none !important;
    }
`;
const StyledNotLarge = styled.div`
    ${({ theme }) => css`
        @media (min-width: 992px) {
            display: none !important;
        }
    `}
`;

const flatListToHierarchical = (
    data = [],
    { idKey = 'key', parentKey = 'parentId', childrenKey = 'children' } = {}
) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item: any) => {
        const newItem = { ...item };
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
        childrenOf[id] = childrenOf[id] || [];
        newItem[childrenKey] = childrenOf[id];
        parentId ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem) : tree.push(newItem);
    });
    return tree;
};

const Header: FC<HeaderProps> = ({ companyInfo }) => {
    const { menuItems } = useStaticQuery(graphql`query NavQuery {
  menuItems: allWpMenuItem(
    filter: {locations: {eq: HEADER_MENU}}
    sort: {order: ASC}
  ) {
    nodes {
      url
      label
      target
      key: id
      parentId
      menuItemExtras {
        featuredTitle
        featuredContent {
          ... on WpPage {
            featuredImage {
              node {
                sourceUrl
                mediaItemUrl
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 300, height: 280, quality: 90, layout: FIXED)
                  }
                }
              }
            }
            uri
            title
          }
        }
      }
    }
  }
}`);

    const menuItemsFiltered = useMemo(
        () => (menuItems?.nodes?.length >= 1 ? flatListToHierarchical(menuItems.nodes) : []),
        [menuItems]
    );

    return (
        <>
            <GHeader
                justify="center"
                background={{
                    dark: true,
                    color: 'brand',
                }}
                elevation="medium"
            >
                <MaxWidth>
                    <Box
                        direction="row"
                        flex={{ shrink: 0 }}
                        pad={{ top: 'medium', bottom: 'medium' }}
                        justify="start"
                        align="center"
                        gap="medium"
                    >
                        <StyledLogo>
                            <Link
                                to="/"
                                style={{
                                    color: `black`,
                                    textDecoration: `none`,
                                }}
                            >
                                <img src={LogoIcon} alt={companyInfo.companyName} />
                            </Link>
                        </StyledLogo>

                        <StyledNotLarge style={{ marginLeft: 'auto' }}>
                            <MobileNavbar items={menuItemsFiltered} />
                        </StyledNotLarge>

                        <StyledLarge>
                            <AnimatedNavbar duration={300} items={menuItemsFiltered} />
                        </StyledLarge>
                        <StyledLarge style={{ marginLeft: 'auto' }}>
                            <HeaderCta />
                        </StyledLarge>
                    </Box>
                </MaxWidth>
            </GHeader>
            <HeaderFrom />
        </>
    );
};

export default Header;
