import React, { FC } from 'react';
import { Box, Heading } from 'grommet';
import styled from 'styled-components';
import { StyledMask } from './imageMasks';
import maskCardimage from '../images/card-mask.svg';
import maskCardbg from '../images/card-bg.svg';
import Link from './link';
import Soon from './soon';

const StyledLink = styled(Link)`
    font-weight: normal;
    color: ${props => props.theme.global.colors.text.light};
`;

interface EntryProps {
    featuredImage: any;
    title: string;
    uri: string;
    type?: string;
    size?: string;
    clickEvt?: (any?) => any;
}
const Entry: FC<EntryProps> = ({ featuredImage, title, uri, type, size = '300px 300px', clickEvt }) => {
    const handleClick = () => {
        if (clickEvt) {
            clickEvt();
        }
    };

    return (
        <Box align="center" onClick={handleClick}>
            <Link to={`${uri}`}>
                {featuredImage ? (
                    <StyledMask
                        size={size}
                        imgSize={size}
                        mask={maskCardimage}
                        image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                        alt={featuredImage.node.altText}
                    />
                ) : (
                    <Soon size={size} />
                )}
            </Link>
            <Box pad={{ bottom: 'large', top: 'medium' }} align="center">
                <Heading level={2} size="small" textAlign="center" margin="none" color="accent-2">
                    <StyledLink to={`${uri}`}>{title}</StyledLink>
                </Heading>
            </Box>
        </Box>
    );
};

export default Entry;
